body {
  background-color: #202124;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.error {
  color: rgb(236, 198, 198);
  font-size: 12px;
  margin-top: 5px;
}

nav {
  display: flex;
  justify-content: end;
}

ul {
  list-style-type: none;
}

li {
  float: left;
  padding: 13px;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.appBtn {
  height: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  position: static;
}

.Search {
  width: 600px;
  height: 40px;
  border-radius: 30px;
  background-color: #202124;
  border-color: rgba(165, 165, 165, 0.612);
  border-style: solid;
  color: white;
  font-size: 17px;
  text-indent: 10px;
}

.Search:hover {
  background-color: #373636;
}

.logo {
  width: 300px;
  padding: 10px;
}

.btns {
  display: flex;
  justify-content: center;
}

.searchbtn {
  background-color: #373636;
  color: white;
  width: 10em;
  height: 40px;
  margin-left: 10px;
  border-radius: 10px;
  border-style: solid;
  border-color: #373636;
  font-size: 17px;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: #181818;
  text-align: center;
  padding: 10px;
}

.panel {
  border-bottom: 1px solid #181818;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.right {
  justify-content: flex-end;
}
